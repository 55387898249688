import React from 'react';
import { makeStyles, Grid, List, Paper } from '@material-ui/core';

import { CatalogResultListItem } from '@backstage/plugin-catalog';
import {
  SearchBar,
  SearchFilter,
  SearchResult,
  DefaultResultListItem,
} from '@backstage/plugin-search';
import { Content, Header, Page } from '@backstage/core-components';

const useStyles = makeStyles((theme) => ({
  bar: {
    padding: theme.spacing(1, 0),
  },
  filters: {
    padding: theme.spacing(2),
  },
  filter: {
    '& + &': {
      marginTop: theme.spacing(2.5),
    },
  },
}));

const SearchPage = () => {
  const classes = useStyles();

  return (
    React.createElement(Page, { themeId: "home",}
      , React.createElement(Header, { title: "Search",} )
      , React.createElement(Content, null
        , React.createElement(Grid, { container: true, direction: "row",}
          , React.createElement(Grid, { item: true, xs: 12,}
            , React.createElement(Paper, { className: classes.bar,}
              , React.createElement(SearchBar, { debounceTime: 100,} )
            )
          )
          , React.createElement(Grid, { item: true, xs: 3,}
            , React.createElement(Paper, { className: classes.filters,}
              , React.createElement(SearchFilter.Select, {
                className: classes.filter,
                name: "kind",
                values: ['Component', 'Template'],}
              )
              , React.createElement(SearchFilter.Checkbox, {
                className: classes.filter,
                name: "lifecycle",
                values: ['experimental', 'production'],}
              )
            )
          )
          , React.createElement(Grid, { item: true, xs: 9,}
            , React.createElement(SearchResult, null
              , ({ results }) => (
                React.createElement(List, null
                  , results.map(({ type, document }) => {
                    switch (type) {
                      case 'software-catalog':
                        return (
                          React.createElement(CatalogResultListItem, {
                            key: document.location,
                            result: document,}
                          )
                        );
                      default:
                        return (
                          React.createElement(DefaultResultListItem, {
                            key: document.location,
                            result: document,}
                          )
                        );
                    }
                  })
                )
              )
            )
          )
        )
      )
    )
  );
};

export const searchPage = React.createElement(SearchPage, null );
